import React from 'react';
import logo from './images/logo-full.png';
import bg from './images/kelli-tungay-2LJ4rqK2qfU-unsplash.jpg'
import './fonts/fonts.css'
import './App.css';


function App() {
  return (
    <div style={{overflow:'hidden',  backgroundImage: "url(" + bg + ")",
    backgroundPosition: '30% 0',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat'}}>
   
      
      <div className='container'>
      <div className='col-4' style={{marginTop:'20px'}}></div>
        
        <div className='col-7' style={{ marginTop: '20px' }}>
          <div style={{  padding:'40px', borderRadius:'9px', boxShadow:'0 5px 10px 4px rgba(0,0,0,0.12)'}}>
          
          <img src={logo} style={{width:'auto', height:'100px'}} alt='logo'/>
          <h1 className={'bigtitle'}>Solutions humaines se réinvente pour mieux vous transformer!</h1>
          <p>Si vous souhaitez obtenir de l'information sur nos produits et services :</p>
          
          <ul>
            <li>Collaboration </li>
            <li>Auto-gestion – Auto-organisation</li>
            <li>Diversité et inclusion</li>
            <li>Intelligence émotionnelle – Leadership agile</li>
            <li>Agilité comportementale</li>
            <li>Réflexion stratégique</li>
            <li>Atelier Mission – Valeurs</li>
            <li>Innovation</li>
            <li>Communication</li>
            <li>Leadership</li>
            <li>Design d'expériences</li>
            <li>Coaching</li>
            <li>Facilitation</li>
            <li>Formation</li>
            <li>Outils psychométriques : MPO, MPO-Communication, EQ-i 2.0, IDI, Agilité comportementale</li>
          
          
          </ul>
        <p>Communiquez avec nous via : <a href='mailto:info@solutionshumaines.com'>info@solutionshumaines.com</a> </p>
        
        
          </div>
        
          </div>
          <div className='col-1' style={{marginTop:'20px'}}></div>
      </div>
      </div>
      
    
    
  );
}

export default App;
